'use strict';

angular.module('ahlulhadith').controller('CategoryController', ['$q', '$rootScope', '$scope', '$state', 'app', 'FirebaseService', 'CategoriesService', 'constants', 'categoryId', 'categories', 'lectureType', function($q, $rootScope, $scope, $state, app, firebaseService, categoriesService, constants, categoryId, categories, lectureType) {
  $scope.app = app;
  $scope.categories = categories;
  $scope.lectureTypes = constants.lectureTypes;
  $scope.categoryId = categoryId;
  $scope.category = categories.all[categoryId];
  $scope.currentTab = 'posts';
  lectureType = lectureType || 'all';
  $scope.currentLectureType = lectureType;
  $scope.posts = [];
  $scope.lineage = categoriesService.getCategoryLineage(app, $scope.category);

  $scope.setTab = function(tab) {
    $scope.currentTab = tab;
  };

  $scope.goToLectureType = function(targetLectureType) {
    if (lectureType == targetLectureType) {
      $scope.setTab('posts');
    } else {
      $state.go('root.category', { categoryId: categoryId, lectureType: targetLectureType });
    }
  };

  $scope.showTitle = function() {
    $rootScope.title.text = $scope.category.name;
    $rootScope.title.hideHeader = false;
  };

  $scope.loadPosts = function() {
    $scope.morePostsLoading = true;

    var defer = $q.defer();
    var promises = [];

    var allDone = function(results) {
      Array.prototype.unshift.apply($scope.posts, results);
      defer.resolve();
      $scope.morePostsLoading = false;
    };

    var loadPostByPostIds = function(postIds) {
      angular.forEach(postIds, function(postId) {
        promises.push(firebaseService.getPost(app, postId.$id));
      });
      $q.all(promises).then(allDone);
    };

    var loadAllPosts = function() {
      var lastPublished = $scope.posts.length > 0 ? _.first($scope.posts).published : 0;
      var postIds = firebaseService.getCategoryPostIds(app, categoryId, $scope.posts.length / constants.pageSize, lastPublished);
      postIds.$loaded()
        .then(function() {
          loadPostByPostIds(postIds);
          if (!postIds || postIds.length == 0 || postIds.length < constants.pageSize) {
            $scope.allPostsLoaded = true;
          }
        })
        .catch(function(err) {
          console.error(err);
        });
    };

    var loadLectureTypePosts = function() {
      var lastPublished = $scope.posts.length > 0 ? _.first($scope.posts).published : 0;
      var postIds = firebaseService.getCategoryLectureTypePostIds(app, categoryId, $scope.lectureTypes[lectureType].value, $scope.posts.length / constants.pageSize, lastPublished);
      postIds.$loaded()
        .then(function() {
          loadPostByPostIds(postIds);
          if (!postIds || postIds.length == 0 || postIds.length < constants.pageSize) {
            $scope.allPostsLoaded = true;
          }
        })
        .catch(function(err) {
          console.error(err);
        });
    };

    if (lectureType == 'all') {
      loadAllPosts();
    } else {
      loadLectureTypePosts();
    }

    return defer.promise;
  };

  $scope.more = function() {
    $scope.loadPosts();
  };

  $scope.showTitle();
  $scope.loadPosts();
}]);