'use strict';

import 'lightgallery.js';
import 'lg-pager.js';
import 'lg-share.js';
import 'lg-zoom.js';
import 'lg-thumbnail.js';

angular.module('ahlulhadith')
  .directive('lightGallery', ['$state', function($state) {
    return {
      scope: {
        options: '=lightGallery',
        app: '=app'
      },
      restrict: 'A',
      link: function(scope, element, attr) {
        var div = $('<div></div>');
        div.css({
          width: 0,
          height: 0,
          overflow: 'hidden',
          margin: 0,
          padding: 0,
        });
        $(element).append(div);

        var images = scope.options.images;
        images.forEach(function(image) {
          div.append('<div data-src="' + image.link + '" data-sub-html=".caption"><img src="' + image.link + '" /><div class="caption">' + image.title + '</div></div>');
        });

        lightGallery(div[0], {
          thumbnail: true,
          showThumbByDefault: false,
          share: true,
          zoom: true,
          actualSize: true,
          pager: true,
          googlePlusDropdownText: 'Google Plus',
          subHtmlSelectorRelative: true,
          index: scope.options.index || 0
        });

        div.bind('onBeforeClose', function(e) {
          div.remove();
          if (window.history.length > 0) {
            window.history.back();
          } else {
            // TODO: Go home
            $state.go('root.home', { app: scope.app });
          }
        });

        var trigger = function (el, event) {
          if (document.createEvent) {
            var evObj = document.createEvent('MouseEvents');
            evObj.initEvent(event, true, false);
            el.dispatchEvent(evObj);
          } else if (document.createEventObject) {
            el.fireEvent('on' + event);
          }
        }

        trigger(div.find('div').eq(0).get(0), 'click');
      }
    };
  }])

  .directive('dlMenu', [function() {
    return {
      restrict: 'A',
      link: function(scope, element, attr) {
        $(element).dlmenu();
        $(element).find('a.menu-item').click(function() {
          $(element).dlmenu('closeMenu');
        });
      }
    };
  }])

  .directive('coverImage', [function() {
    return {
      scope: {
        post: '=coverImage'
      },
      restrict: 'A',
      link: function(scope, element, attr) {
        if (scope.post.cover_image) {
          $(element).attr('src', scope.post.cover_image);
          return;
        }

        var hashCode = function(s) {
          var h = 0, l = s.length, i = 0;
          if ( l > 0 )
            while (i < l)
              h = (h << 5) - h + s.charCodeAt(i++) | 0;
          return h;
        };

        var width = 800;
        var height = 600;
        if (attr.size == 'small') {
          width = 160;
          height = 120;
        } else if (attr.size == 'medium') {
          width = 322;
          height = 242;
        }

        var fallbackImages = [
          "https://images.unsplash.com/photo-1462293781490-9637490a111d?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=9441fe8032d166fb2b7787d5df7c6656".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/40/KJyFV5SZSweiYGhMhrqC_MD4817.jpg?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=5ff254a8b8df460c47a0abd484e5871c".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1469799030856-9039ae90a014?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=fe940dcd08fedbf5784f4d6ad353ca05".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1422984641758-deb09e05eec5?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=b04b04e9cd861e5609bef831b0ccd9b6".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1462927276862-e12992170abb?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=027f9ade2a1a4e738fb08125f60c9c11".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1445862579103-f772aa61bd5b?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=3efbc6ba1fcd04cb12ca44a251645da9".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1433277587075-787d4b98b4ce?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=d66ce2db213f1c6f90a4101bdf0201a8".replace("%WIDTH%", width).replace("%HEIGHT%", height),
          "https://images.unsplash.com/photo-1463315424163-0a7dea21f8f2?ixlib=rb-0.3.5&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=%WIDTH%&h=%HEIGHT%&fit=crop&s=4208c8047c73449d01fa7b4f1b8a01c6".replace("%WIDTH%", width).replace("%HEIGHT%", height)
        ];

        $(element).attr('src', fallbackImages[Math.abs(hashCode(scope.post.$id) % fallbackImages.length)])
      }
    };
  }])

  .directive('post', [function() {
    return {
      restrict: 'E',
      template: require('../../templates/partials/post.html'),
      scope: {
        app: '=app',
        post: '=post'
      },
      controller: ['angularPlayer', 'constants', '$scope', function(angularPlayer, constants, $scope) {
        $scope.postTypeIcon = function() {
          if ($scope.post.lecture_type == constants.lectureTypes.articles.value && $scope.post.category == constants.postCategories.texts) {
            return 'fa-file-text-o';
          } else if ($scope.post.lecture_type == constants.lectureTypes.articles.value && $scope.post.category == constants.postCategories.images) {
            return 'fa-picture-o';
          } else if ($scope.post.category == constants.postCategories.videos) {
            return 'fa-youtube-play';
          } else {
            return 'fa-microphone';
          }
        };

        $scope.partsText = function() {
          if ($scope.post.count == undefined) {
            return;
          }
          if ($scope.post.lecture_type == constants.lectureTypes.articles.value) {
            return $scope.post.count == 1 ? '1 page' : $scope.post.count + ' pages';
          } else {
            return $scope.post.count == 1 ? '1 part' : $scope.post.count + ' parts';
          }
        };

        $scope.currentlyPlaying = function() {
          var currentTrack = angularPlayer.currentTrackData();
          if (angularPlayer.isPlayingStatus() && currentTrack && currentTrack.postId == $scope.post.$id) {
            return true;
          }
        };

      }]
    };
  }])

  .directive('searchResult', [function() {
    return {
      restrict: 'E',
      template: require('../../templates/partials/search-result.html'),
      scope: {
        app: '=app',
        searchResult: '=searchResult'
      },
      controller: ['constants', '$scope', function(constants, $scope) {
        var typeFunction = function() {
          var post = $scope.searchResult.post;
          if (post.lecture_type === constants.lectureTypes.articles.value && post.category == constants.postCategories.texts) {
            $scope.postTypeText = 'Article';
            $scope.partsText = post.count === 1 ? '1 page' : post.count + ' pages';
            $scope.postTypeIcon = 'fa-file-text-o';
          } else if (post.lecture_type === constants.lectureTypes.articles.value && post.category == constants.postCategories.images) {
            $scope.postTypeText = 'Picture';
            $scope.partsText = post.count === 1 ? '1 part' : post.count + ' parts';
            $scope.postTypeIcon = 'fa-picture-o';
          } else if (post.category === constants.postCategories.videos) {
            $scope.postTypeText = 'Video';
            $scope.partsText = post.count === 1 ? '1 part' : post.count + ' parts';
            $scope.postTypeIcon = 'fa-youtube-play';
          } else {
            $scope.postTypeText = 'Audio';
            $scope.partsText = post.count === 1 ? '1 part' : post.count + ' parts';
            $scope.postTypeIcon = 'fa-microphone';
          }
        };

        typeFunction();
      }]
    };
  }])

  .directive('postCategories', [function() {
    return {
      restrict: 'E',
      template: require('../../templates/partials/categories-tags.html'),
      scope: {
        app: '=app',
        post: '=post',
        categories: '=categories'
      },
      controller: ['FirebaseService', '$scope', function(firebaseService, $scope) {
        $scope.postCategories = [];
        this.setCategories = function() {
          var categoryIds = firebaseService.getCategoryIdsForPost($scope.app, $scope.post.$id);
          categoryIds.$loaded()
            .then(function() {
              categoryIds.forEach(function(categoryId) {
                $scope.postCategories.push($scope.categories.all[categoryId.$id]);
              });
            })
            .catch(function(err) {
              console.error(err);
            });
        };
      
        this.setCategories();
      }]
    };
  }])

  .directive('autoScroll', ['$document', '$timeout', '$location', function($document, $timeout, $location) {
    return {
      restrict: 'A',
      link: function(scope, element, attrs) {
        scope.saveScrollFlag = true;

        scope.scrollPos = {};

        $document.bind('scroll', function() {
          if (scope.saveScrollFlag) {
            scope.scrollPos[$location.path()] = $(window).scrollTop();
          }
        });

        scope.scrollClear = function(path) {
          scope.scrollPos[path] = 0;
        };

        scope.$on('$locationChangeSuccess', function(route) {
          $timeout(function() {
            $(window).scrollTop(scope.scrollPos[$location.path()] ? scope.scrollPos[$location.path()] : 0);
            scope.saveScrollFlag = true;
          }, 0);
        });

        scope.$on('$locationChangeStart', function(event) {
          scope.saveScrollFlag = false;
        });
      }
    };
  }])

  .directive('mySlick', [function() {
    return {
      restrict: 'A',
      link: function(scope, element) {
        $(element).slick({
				  infinite: true,
				  speed: 200,
				  autoplay:true,
				  dots:true,
				  slidesToShow: 4,
				  responsive: [
						{
						  breakpoint: 1024,
						  settings: {
							slidesToShow: 2,
							slidesToScroll: 2,
							infinite: true,
							dots: true
						  }
						},
						{
						  breakpoint: 600,
						  settings: {
							slidesToShow: 2,
							slidesToScroll: 2
						  }
						},
						{
						  breakpoint: 480,
						  settings: {
							slidesToShow: 1,
							slidesToScroll: 1
						  }
						}
					]
        });
      }
    };
  }]);