'use strict';

angular.module('ahlulhadith')
  .factory('$exceptionHandler', function () {
    return function (exception, cause) {
      bugsnagClient.notify(exception, {
        beforeSend: function (report) {
          report.updateMetaData('angular', { cause: cause });
        }
      });
    };
  });