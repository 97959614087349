'use strict';

angular.module('ahlulhadith').service('CategoriesService', ['$q', 'FirebaseService', function($q, firebaseService) {

  this.categories = {};
  this.status = {};
  this.promises = {};

  this.get = function(app) {
    if (this.status[app] && this.status[app].completed) {
      return this.categories[app];
    }
    if (this.status[app] && this.status[app].busy) {
      return this.promises[app];
    }
    if (!this.status[app]) {
      this.status[app] = {};
    }
    var self = this;
    this.status[app].busy = true;
    this.promises[app] = firebaseService.getCategories(app).$loaded().then(function(categories) {
      self.status[app].completed = true;
      self.status[app].busy = false;
      self.categories[app] = self.rearrangeCategories(categories);
      return self.categories[app];
    }, function() {
      self.status[app].busy = false;
      return $q.reject('Error');
    });
    return this.promises[app];
  };

  this.rearrangeCategories = function(rawCategories) {
    var topLevelCategories = [];
    var subCategoriesMap = {};
    var allCategoriesMap = {};
    angular.forEach(rawCategories, function(rawCategory) {
      allCategoriesMap[rawCategory.$id] = rawCategory;
      if (!rawCategory.parent) {
        topLevelCategories.push(rawCategory);
      } else {
        if (subCategoriesMap[rawCategory.parent]) {
          subCategoriesMap[rawCategory.parent].push(rawCategory);
        } else {
          subCategoriesMap[rawCategory.parent] = [rawCategory];
        }
      }
    });
    return { top: topLevelCategories, sub: subCategoriesMap, all: allCategoriesMap };
  };

  this.getCategoryLineage = function(app, category) {
    if (!this.categories[app].all || !category.parent) {
      return [];
    }
    var thisCategory = category;
    var lineage = [];
    while (thisCategory.parent) {
      var parent = this.categories[app].all[thisCategory.parent];
      lineage.unshift(parent);
      thisCategory = parent;
    }
    return lineage;
  };

}]);