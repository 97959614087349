'use strict';

angular.module('ahlulhadith').controller('LectureTypeController', ['$q', '$rootScope', '$scope', 'app', 'FirebaseService', 'constants', 'categories', 'lectureType', function($q, $rootScope, $scope, app, firebaseService, constants, categories, lectureType) {
  $scope.app = app;
  $scope.categories = categories;
  $scope.lectureTypes = constants.lectureTypes;
  lectureType = lectureType || 'all'
  $scope.posts = [];

  $scope.getCurrentLectureTypeName = function() {
    return $scope.lectureTypes[lectureType].name;
  };

  $scope.showTitle = function() {
    $rootScope.title.text = $scope.getCurrentLectureTypeName();
    $rootScope.title.hideHeader = false;
  };

  $scope.loadPosts = function() {
    var defer = $q.defer();
    var promises = [];

    var allDone = function(results) {
      Array.prototype.unshift.apply($scope.posts, results);
      defer.resolve();
    };

    var loadPostByPostIds = function(postIds) {
      angular.forEach(postIds, function(postId) {
        promises.push(firebaseService.getPost(app, postId.$id));
      });
      $q.all(promises).then(allDone);
    };

    var loadAllPosts = function() {
      var lastPublished = $scope.posts.length > 0 ? _.first($scope.posts).published : 0;
      var postIds = firebaseService.getLectureTypePostIds(app, $scope.lectureTypes[lectureType].value, $scope.posts.length / constants.pageSize, lastPublished);
      postIds.$loaded()
        .then(function() {
          loadPostByPostIds(postIds);
          if (!postIds || postIds.length == 0 || postIds.length < constants.pageSize) {
            $scope.allPostsLoaded = true;
          }
        })
        .catch(function(err) {
          console.error(err);
        });
    };

    loadAllPosts();

    return defer.promise;
  };

  $scope.more = function() {
    $scope.loadPosts();
  };

  $scope.showTitle();
  $scope.loadPosts();
}]);