'use strict';

angular.module('ahlulhadith').controller('PostsController', ['constants', '$scope', 'FirebaseService', 'app', 'posts', function(constants, $scope, firebaseService, app, posts) {
  $scope.app = app;
  $scope.posts = posts;
  $scope.allPostsLoaded = false;

  $scope.more = function() {
    $scope.morePostsLoading = true;
    var morePosts = firebaseService.getAllPosts(app, $scope.posts.length / constants.pageSize, _.first($scope.posts).published);
    morePosts.$loaded()
      .then(function() {
        $scope.morePostsLoading = false;
        Array.prototype.unshift.apply($scope.posts, morePosts);
        if (!morePosts || morePosts.length == 0 || morePosts.length < constants.pageSize) {
          $scope.allPostsLoaded = true;
        }
      })
      .catch(function(err) {
        $scope.morePostsLoading = false;
        console.error(err);
      });
  };
}]);