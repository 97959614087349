'use strict';

angular.module('ahlulhadith').controller('RootController', ['$scope', '$rootScope', '$state', '$location', 'constants', '$anchorScroll', 'angularPlayer', 'app', '$translate', function($scope, $rootScope, $state, $location, constants, $anchorScroll, angularPlayer, app, $translate) {
  $scope.app = app;
  $translate.use(app);

  $scope.appTitle = function(app) {
    return constants.apps[app].name;
  };

  $scope.title = function() {
    return $rootScope.title.text || 'All Posts';
  };

  $scope.stateMenuMap = {
    'root.aboutus': 'aboutus',
    'root.contactus': 'contactus',
    'root.appsLinks': 'apps',
    'root.recommendations': 'aboutus',
    'root.publications': 'aboutus'
  };

  $scope.isActiveMenu = function(menu) {
    if (menu == 'home' && !$scope.stateMenuMap[$state.current.name]) {
      return true;
    } else if ($scope.stateMenuMap[$state.current.name] == menu) {
      return true;
    }
    return false;
  };

  $scope.showHeader = function() {
    return !$rootScope.title.hideHeader;
  };

  $scope.getApp = function() {
    return $location.path().split('/')[1];
  };

  $scope.homeLink = function() {
    return "/" + $scope.getApp();
  };

  $scope.appsLink = function() {
    return $scope.homeLink() + "/apps";
  };

  $scope.scrollToTop = function() {
    $anchorScroll();
  };

  // Player controls
  $scope.togglePlay = function() {
    if (angularPlayer.isPlayingStatus()) {
      angularPlayer.pause();
    } else {
      angularPlayer.play();
    }
  };

  $scope.closePlayer = function() {
    angularPlayer.stop();
    angularPlayer.setCurrentTrack(null);
    angularPlayer.clearPlaylist(function() {
      // currentPlaying is not getting cleared. So we reset it here
      $scope.currentPlaying = null;
    });
  };

  $scope.search = function (query) {
    $state.go('root.search', {
      app: $state.params.app,
      q: query
    });
  };

}]);