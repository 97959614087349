'use strict';

angular.module('ahlulhadith').config(['$translateProvider', function($translateProvider) {
  var english = {
    APP: {
      TITLE: 'Ahlul Hadith'
    },
    HEADERS: {
      HOME: 'Home',
      KITAB_CLASSES: 'Kitab Classes',
      PUBLIC_LECTURES: 'Public Lectures',
      ARTICLES: 'Articles',
      QA: 'Q&A'
    },
    HOME: {
      INTRO1: 'Welcome to our website!',
      INTRO2: "Makthaba Uthmaan Ibn Affan (Uthmaan Ibn Affan Library) was established to facilitate the learning, teaching, and spreading of the Ilm (Knowledge) of the Qur'an and the Sunnah, as understood and implemented by the Jamaa'ah of Sahabah (the Companions of the Prophet ﷺ).",
      TERRORISM1: 'The evil of terrorism and Islamic refutations to it',
    },
    PUBLICATIONS: {
      TITLE: 'Publications',
      INTRO: 'Contact (+91 99623 15876) to get your copies. You can also download them for free here.'
    }
  };
  
  var tamil = {
    HOME: {
      INTRO1: 'எங்கள் இணையத்திற்கு உங்களை வரவேற்கிறோம்!',
      INTRO2: "உஸ்மான் இப்னு அஃப்பான் நூலகம் அல்-குர்ஆன், அஸ்-ஸுன்னாஹ்வை ஸஹாபா என்ற ஜமாஅத் எவ்வாறு விளங்கி செயல்படுத்தினார்களோ அந்த கல்வியைக் கற்று செயல்படுத்தி மக்களுக்கு மத்தியில் பரவலாக்குவதற்காக நிறுவப்பட்டதாகும்.",
      TERRORISM1: 'தீவிரவாதமும் இஸ்லாமிய மறுப்புகளும்',
    },
    PUBLICATIONS: {
      TITLE: 'வெளியீடுகள்',
      INTRO: 'உங்கள் பிரதிகளைப் பெற்றுக்கொள்ள (+91 99623 15876) இந்த எண்ணைத் தொடர்பு கொள்ளவும். இவற்றை நீங்கள் இலவசமாகவும் டவுன்லோடு செய்துகொள்ளலாம்.'
    }
  };

  $translateProvider.translations('en', english);
  $translateProvider.translations('ta', tamil);
  $translateProvider.preferredLanguage('ta');
  $translateProvider.useSanitizeValueStrategy('sce');
 
}]);