'use strict';

import * as firebase from 'firebase/app';
import 'firebase/database';

angular.module('ahlulhadith').service('FirebaseService', ['constants', '$firebaseObject', '$firebaseArray', function(constants, $firebaseObject, $firebaseArray) {

  function init() {
    var config = {
      apiKey: "AIzaSyB8izxr8U5UB9YoZ3pbMMd67eyHyuRCdnY",
      authDomain: "react-salafi-content.firebaseapp.com",
      databaseURL: "https://react-salafi-content.firebaseio.com",
      projectId: "react-salafi-content",
      storageBucket: "react-salafi-content.appspot.com",
      messagingSenderId: "205366244388"
    };
    firebase.initializeApp(config);
  }

  this.firebaseObject = function (ref) {
    return $firebaseObject(ref);
  };

  this.getAllPosts = function(app, pageNumber, lastPostPublished, pageSize) {
    if (pageNumber === undefined) pageNumber = 0;
    if (lastPostPublished === undefined) lastPostPublished = 0;
    if (pageSize === undefined) pageSize = constants.pageSize;
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_posts')
      .orderByChild('published')
      .limitToLast(pageSize);
    if (pageNumber > 0 && lastPostPublished > 0) {
      ref = ref.endAt(lastPostPublished - 1);
    }
    return $firebaseArray(ref);
  };

  this.getPost = function(app, postId) {
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_posts').child(postId);
    return $firebaseObject(ref);
  };

  this.getItems = function(app, postId) {
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_items').child(postId).orderByChild('position');
    return $firebaseArray(ref);
  };

  this.getItem = function(app, postId, itemId) {
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_items').child(postId).child(itemId);
    return $firebaseObject(ref);
  };

  this.getTextItem = function(app, itemId) {
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_texts').child(itemId);
    return $firebaseObject(ref);
  };

  this.getFeaturedPostIds = function(app) {
    var featuredPostsRef = firebase.database().ref().child(constants.apps[app].root).child('content').child('pub_fposts')
      .orderByValue();
    return $firebaseArray(featuredPostsRef);
  };

  this.getCategories = function(app) {
    var categoriesRef = firebase.database().ref().child(constants.apps[app].root).child('content').child('categories')
      .orderByChild('order');
    return $firebaseArray(categoriesRef);
  };

  this.getCategoryPostIds = function(app, categoryId, pageNumber, lastPostPublished, pageSize) {
    if (pageNumber === undefined) pageNumber = 0;
    if (lastPostPublished === undefined) lastPostPublished = 0;
    if (pageSize === undefined) pageSize = constants.pageSize;
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('categories_posts').child(categoryId)
      .orderByValue()
      .limitToLast(pageSize);
    if (pageNumber > 0 && lastPostPublished > 0) {
      ref = ref.endAt(lastPostPublished - 1);
    }
    return $firebaseArray(ref);
  };

  this.getLectureTypePostIds = function(app, lectureType, pageNumber, lastPostPublished, pageSize) {
    if (pageNumber === undefined) pageNumber = 0;
    if (lastPostPublished === undefined) lastPostPublished = 0;
    if (pageSize === undefined) pageSize = constants.pageSize;
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('l_types_posts').child(lectureType)
      .orderByValue()
      .limitToLast(pageSize);
    if (pageNumber > 0 && lastPostPublished > 0) {
      ref = ref.endAt(lastPostPublished - 1);
    }
    return $firebaseArray(ref);
  };

  this.getCategoryLectureTypePostIds = function(app, categoryId, lectureType, pageNumber, lastPostPublished, pageSize) {
    if (pageNumber === undefined) pageNumber = 0;
    if (lastPostPublished === undefined) lastPostPublished = 0;
    if (pageSize === undefined) pageSize = constants.pageSize;
    var ref = firebase.database().ref().child(constants.apps[app].root).child('content').child('categories_l_types_posts').child(categoryId).child(lectureType)
      .orderByValue()
      .limitToLast(pageSize);
    if (pageNumber > 0 && lastPostPublished > 0) {
      ref = ref.endAt(lastPostPublished - 1);
    }
    return $firebaseArray(ref);
  };

  this.getCategoryIdsForPost = function(app, postId) {
    var postsCategoriesRef = firebase.database().ref().child(constants.apps[app].root).child('content').child('posts_categories').child(postId)
      .orderByValue();
    return $firebaseArray(postsCategoriesRef);
  };

  this.getSearchRequestsRef = function (app) {
    return firebase.database().ref().child(constants.apps[app].root).child('search').child('requests');
  };

  this.getSearchResultRef = function (app, requestKey) {
    return firebase.database().ref().child(constants.apps[app].root).child('search').child('responses').child(requestKey);
  };

  // Initialize firebase
  init();
}]);