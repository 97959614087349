'use strict';

angular
  .module('ahlulhadith')
  .directive('postList', postList);

function postList() {
  return {
    restrict: 'EA',
    replace: false,
    template: require('../../templates/partials/post-list.html')
  };
}