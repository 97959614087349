'use strict';

import NProgress from 'nprogress';

angular.module('ahlulhadith').controller('TextPostController', ['constants', '$rootScope', '$scope', '$sce', 'FirebaseService', '$anchorScroll', 'app', 'post', 'items', 'categories', function(constants, $rootScope, $scope, $sce, firebaseService, $anchorScroll, app, post, items, categories) {
  $scope.app = app;
  $scope.post = post;
  $scope.items = items;
  $scope.currentPage = 0;
  $scope.pageContent = 'Loading...';
  $rootScope.title.text = $scope.post.title;
  $rootScope.title.hideHeader = false;
  $scope.categories = [];

  $scope.pageHistory = {};

  $scope.setPageContent = function(page) {
    NProgress.start();
    var textItem = firebaseService.getTextItem($scope.app, $scope.items[page].$id);
    textItem.$loaded()
      .then(function() {
        $scope.pageContent = $sce.trustAsHtml(textItem.text);
        $scope.currentPage = page;
        if (!$scope.pageHistory[page]) {
          $anchorScroll('page-content');
        }
        $scope.pageHistory[page] = true;
        NProgress.done();
      })
      .catch(function(err) {
        console.error(err);
        NProgress.done();
      });
  };

  this.setCategories = function() {
    var categoryIds = firebaseService.getCategoryIdsForPost(app, post.$id);
    categoryIds.$loaded()
      .then(function() {
        categoryIds.forEach(function(categoryId) {
          $scope.categories.push(categories.all[categoryId.$id]);
        });
      })
      .catch(function(err) {
        console.error(err);
      });
  };

  this.setCategories();
  $scope.setPageContent($scope.currentPage);

}]);