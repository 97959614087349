'use strict';

angular.module('ahlulhadith').controller('HomeController', ['$q', '$rootScope', '$scope', 'app', 'FirebaseService', 'constants', 'featuredPostIds', 'categories', function($q, $rootScope, $scope, app, firebaseService, constants, featuredPostIds, categories) {
  $rootScope.title.hideHeader = true;
  $scope.app = app;
  $scope.categories = categories;
  $scope.lectureTypes = constants.lectureTypes;

  this.fetchFeaturedPosts = function() {
    var defer = $q.defer();
    var promises = [];

    var allDone = function(results) {
      $scope.featuredPosts = results;
      defer.resolve();
    };

    angular.forEach(featuredPostIds, function(featuredPostId) {
      promises.push(firebaseService.getPost(app, featuredPostId.$id));
    });

    $q.all(promises).then(allDone);

    return defer.promise;
  };

  this.fetchFeaturedPosts();

}]);