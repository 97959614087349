'use strict';

import NProgress from 'nprogress';

angular.module('ahlulhadith').controller('SearchController', ['q', 'app', '$rootScope', '$scope', 'SearchService', function(query, app, $rootScope, $scope, searchService) {
  $rootScope.title.text = query + ': search results';
  $rootScope.title.hideHeader = true;
  $scope.query = query;

  $scope.search = function (query) {
    if (!query || query.length == 0) {
      return;
    }
    NProgress.start();
    $scope.searching = true;
    searchService.search(app, query).then(function (results) {
      $scope.results = results;
    }).catch(function (error) {

    }).finally(function () {
      NProgress.done();
      $scope.searching = false;
    });
  };

  $scope.search(query);
}]);