'use strict';

angular.module('ahlulhadith').controller('ImagePostController', ['$scope', '$rootScope', '$state', 'post', 'items', function($scope, $rootScope, $state, post, items) {
  $scope.post = post;
  $scope.items = items;
  $rootScope.title.text = post.title;
  $rootScope.title.hideHeader = false;

  // Load the correct index if it is deep linked to an item
  if ($state.params.itemId) {
    var index = _.findIndex($scope.items, function (item) {
      return item.$id == $state.params.itemId;
    });
    if (index >= 0) {
      $scope.index = index;
    }
  }
}]);