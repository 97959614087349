'use strict';

angular.module('ahlulhadith').service('AudioService', [function() {

  this.play = function(angularPlayer, track) {
    if (this.playIfPaused(angularPlayer, track)) {
      return;
    }
    var playlist = angularPlayer.getPlaylist();
    if (playlist && playlist.length > 0) {
      angularPlayer.stop();
      angularPlayer.setCurrentTrack(null);
      var self = this;
      angularPlayer.clearPlaylist(function(data) {
        self.addAndPlayTrack(angularPlayer, track);
      });
    } else {
      this.addAndPlayTrack(angularPlayer, track);
    }
  };

  this.addAndPlayTrack = function(angularPlayer, track) {
    var trackId = angularPlayer.addTrack(track);
    angularPlayer.playTrack(trackId);
  };

  this.playIfPaused = function(angularPlayer, track) {
    var currentTrack = angularPlayer.currentTrackData();
    if (currentTrack && currentTrack.id == track.id) {
      angularPlayer.play();
      return true;
    }
  };

}]);