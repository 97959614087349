'use strict';

angular.module('ahlulhadith').constant('constants', {
  pageSize: 10,
  apps: {
    ta: {
      root: 'tamil',
      name: 'தமிழ்'
    },
    en: {
      root: 'english',
      name: 'English'
    }
  },
  postCategories: {
    audios: 'Audios',
    texts: 'Text',
    images: 'Images',
    videos: 'Videos'
  },
  lectureTypes: {
    'all': {
      value: '-1',
      name: 'All Posts'
    },
    'public-lectures': {
      value: '0',
      name: 'Public Lectures'
    },
    books: {
      value: '1',
      name: 'Kitab Classes'
    },
    qa: {
      value: '2',
      name: 'Questions & Answers'
    },
    articles: {
      value: '3',
      name: 'Articles'
    }
  }
});