'use strict';

angular.module('ahlulhadith').controller('MainController', ['$scope', '$rootScope', '$location', function($scope, $rootScope, $location) {

  $rootScope.title = {
    hideHeader: true
  };

  $scope.pageTitle = function() {
    if ($rootScope.title.text) {
      return $rootScope.title.text + ' | Uthman Ibn Affan Library';
    } else {
      return 'Uthman Ibn Affan Library';
    }
  };

  $scope.pageDescription = function() {
    if ($rootScope.title.text) {
      return $rootScope.title.text;
    } else {
      return 'Uthman Ibn Affan Library';
    }
  };

  $scope.pageImage = function() {
    return "/images/coverlogo.jpg";
  };

  $scope.pageUrl = function() {
    return $location.absUrl();
  };

  $scope.pageType = function() {
    return 'article';
  };

}]);