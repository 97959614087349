'use strict';

angular.module('ahlulhadith').controller('VideoItemController', ['$rootScope', '$scope', 'categories', 'post', 'items', 'item', function($rootScope, $scope, categories, post, items, item) {
  $scope.post = post;
  $scope.items = items;
  $scope.item = item;
  $scope.categories = categories;

  $rootScope.title.text = item.title;
  $rootScope.title.hideHeader = true;
}]);