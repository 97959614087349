'use strict';

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

angular.module('ahlulhadith', [
    'ui.router',                // Angular flexible routing
    'pascalprecht.translate',   // Angular translations
    'firebase',                 // Firebase
    'slick',                    // Slick
    'angular-ladda',            // Ladda
    'angularSoundManager',      // Angular Sound Manager 2
    'angularMoment',            // Angular Moment
    'addthis',                  // addthis
    'youtube-embed',            // youtube embex
])
.config(['$addthisProvider', function ($addthisProvider) {
    $addthisProvider.config({ pubid: 'ra-504c6f33725c29d7' });
}]);
