'use strict';

angular.module('ahlulhadith').controller('PublicationsController', ['$scope', '$rootScope', 'app', 'key', '$translate', function($scope, $rootScope, app, key, $translate) {
  $scope.app = app;
  $scope.key = key;

  $scope.publicationsFilter = function(publication) {
    return !key || key === publication.key;
  };

  if (app == 'en') {
    $scope.publications = [
      {
        key: 'protests',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fprotest.jpg?alt=media&token=8466e3da-20ba-451d-92f1-156ab6df2934',
        title: 'Demonstrations and Protests are not the means to the rectification of societies',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fprotests.pdf?alt=media&token=8ffe353a-2185-48f2-bfda-30162946dc46'
      },
      {
        key: 'meelad',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fmeelad.jpg?alt=media&token=a07d5806-3d36-4ab4-a2b5-fbe885ba2357',
        title: 'Are Meelad celebrations for the birth of the Prophet?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fmeelad.pdf?alt=media&token=f8a49693-523a-41b6-a649-6ecda82cc7b4'
      },
      {
        key: 'kabr-masjid',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fkabrmasjid.jpg?alt=media&token=73b6f1b3-c5b6-4aef-835a-f7205e6dcf8c',
        title: 'Is it forbidden to pray in the masjids which have graves in them?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fkabr-masjid.pdf?alt=media&token=b94bba01-b379-4f95-9256-a2a4d7c91ab8'
      },
      {
        key: 'ittiba-taqlid',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsalafisufi.jpg?alt=media&token=b42cdfbd-6bf1-4547-a1eb-c0d759c36c6e',
        title: 'Connecting with the scholars: The upright Salafi way and the blind-following Sufi way (Book)'
      },
      {
        key: 'taraweeh',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ftaraweeh%2Ftaraweeh-cover-english.jpg?alt=media&token=3584214f-b9f8-45c5-9598-adf4750b2673',
        title: 'The Tarāweeh prayer',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ftaraweeh%2Ftaraweeh-english.pdf?alt=media&token=8adc7502-677d-4c82-ba12-3237390d8266'
      },
      {
        key: 'ithiqaf',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fithiqaf.jpg?alt=media&token=c0cca653-dbf2-4549-bb4f-472fefa6bf99',
        title: 'The Places specified for I\'thiqaf',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fithiqaf.pdf?alt=media&token=0f436e8e-1dcc-48ef-a309-62e4388a5742'
      },
      {
        key: 'suthra',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsuthra.jpg?alt=media&token=9b857c9e-8da9-4f21-8ac7-d58933288a1b',
        title: 'The Obligation of placing the Suthra for the one who prays',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsuthra.pdf?alt=media&token=1bc797b1-158e-475a-bd0a-c181d7b572ca'
      },
      {
        key: 'udhhiyyah',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fudhhiyya.jpg?alt=media&token=ac7faa14-a292-42ef-b887-c2e35a43a40d',
        title: 'Is it an innovation to establish Udhhiyyah in groups?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fudhhiyyah-sharing.pdf?alt=media&token=146baa95-4f1d-474d-8e3d-4d65de63410b'
      },
      {
        key: 'shawwal-6-fasts',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2F6shawwal.jpg?alt=media&token=997c693d-41df-466e-896f-1591016e21f7',
        title: 'Shawwal and its recommended 6 fasts',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fshawwah-6-fasts.pdf?alt=media&token=ddd85c0a-5266-4359-810b-d774af8c111e'
      },
      {
        key: 'salat-jamm',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fjamm.jpg?alt=media&token=a182c4dd-73d5-4bfd-85b0-47070a4e9e2f',
        title: 'Is it from the Sunnah to join the prayers without any reason?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fjamm.pdf?alt=media&token=956e91b4-4758-4eb1-81f6-1fbc1af90c40'
      },
      {
        key: 'fidhya',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ffidhya.jpg?alt=media&token=0d9915a2-a2be-478f-b1cf-9e46f0163c8d',
        title: 'Who can leave the fasting and give Fidya instead?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ffidhya.pdf?alt=media&token=87b52bf7-bc16-447b-9104-d5249eb7c5cd'
      },
      {
        key: 'aashura',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Faashura.jpg?alt=media&token=69a83aee-37cc-4df2-a0f2-b545ec4812f6',
        title: 'The Fasts of Aashura',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fashura.pdf?alt=media&token=b9922fa6-d1b5-4043-9791-cba1bba7803c'
      }
    ];
  } else {
    $scope.publications = [
      {
        key: 'protests',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fprotest.jpg?alt=media&token=8466e3da-20ba-451d-92f1-156ab6df2934',
        title: 'போராட்டமும் ஆர்ப்பாட்டமும் இஸ்லாமிய வழிமுறை அல்ல',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fprotests.pdf?alt=media&token=8ffe353a-2185-48f2-bfda-30162946dc46'
      },
      {
        key: 'meelad',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fmeelad.jpg?alt=media&token=a07d5806-3d36-4ab4-a2b5-fbe885ba2357',
        title: 'மீலாது நபிவிழா நபியின் பிறப்பிக்கா இறப்பிற்கா?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fmeelad.pdf?alt=media&token=f8a49693-523a-41b6-a649-6ecda82cc7b4'
      },
      {
        key: 'kabr-masjid',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fkabrmasjid.jpg?alt=media&token=73b6f1b3-c5b6-4aef-835a-f7205e6dcf8c',
        title: 'கப்ருகள் இருக்கும் பள்ளிவாசல்களில் தொழக் கூடாதா?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fkabr-masjid.pdf?alt=media&token=b94bba01-b379-4f95-9256-a2a4d7c91ab8'
      },
      {
        key: 'ittiba-taqlid',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsalafisufi.jpg?alt=media&token=b42cdfbd-6bf1-4547-a1eb-c0d759c36c6e',
        title: 'உலமாக்களின் தொடர்பில் ஸலஃபி சக்தியும் சூஃபி பக்தியும் (புத்தகம்)'
      },
      {
        key: 'taraweeh',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ftaraweeh%2Ftaraweeh-cover-tamil.jpg?alt=media&token=749df390-9f53-456b-9002-e834f6087691',
        title: 'தராவீஹ் தொழுகை',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ftaraweeh%2Ftaraweeh-tamil.pdf?alt=media&token=7714cc13-8264-4e91-aca9-f08fd914de64'
      },
      {
        key: 'ithiqaf',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fithiqaf.jpg?alt=media&token=c0cca653-dbf2-4549-bb4f-472fefa6bf99',
        title: 'இஃதிகாஃப் இருக்கும் இடங்கள்',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fithiqaf.pdf?alt=media&token=0f436e8e-1dcc-48ef-a309-62e4388a5742'
      },
      {
        key: 'suthra',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsuthra.jpg?alt=media&token=9b857c9e-8da9-4f21-8ac7-d58933288a1b',
        title: 'தொழுபவர் ஸுத்ரா (தடுப்பு) வைப்பதன் கடமையும் அதன் சட்டங்களும்',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fsuthra.pdf?alt=media&token=1bc797b1-158e-475a-bd0a-c181d7b572ca'
      },
      {
        key: 'udhhiyyah',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fudhhiyya.jpg?alt=media&token=ac7faa14-a292-42ef-b887-c2e35a43a40d',
        title: 'உழ்ஹிய்யா குர்பானியில் கூட்டுச் சேர்வது பித்அத்தா?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fudhhiyyah-sharing.pdf?alt=media&token=146baa95-4f1d-474d-8e3d-4d65de63410b'
      },
      {
        key: 'shawwal-6-fasts',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2F6shawwal.jpg?alt=media&token=997c693d-41df-466e-896f-1591016e21f7',
        title: 'ஷவ்வால் மாதமும் அதன் ஆறு நோன்புகளும்',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fshawwah-6-fasts.pdf?alt=media&token=ddd85c0a-5266-4359-810b-d774af8c111e'
      },
      {
        key: 'salat-jamm',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fjamm.jpg?alt=media&token=a182c4dd-73d5-4bfd-85b0-47070a4e9e2f',
        title: 'எவ்வித காரணமும் இல்லாமல் தொழுகையை சேர்த்து தொழுவது நபி அவர்களின் வழிமுறையா?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fjamm.pdf?alt=media&token=956e91b4-4758-4eb1-81f6-1fbc1af90c40'
      },
      {
        key: 'fidhya',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ffidhya.jpg?alt=media&token=0d9915a2-a2be-478f-b1cf-9e46f0163c8d',
        title: 'நோன்பை நோற்காமல் ஃபித்யா (பரிகாரம்) கொடுக்க வேண்டியவர்கள் யார்?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Ffidhya.pdf?alt=media&token=87b52bf7-bc16-447b-9104-d5249eb7c5cd'
      },
      {
        key: 'aashura',
        link: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Faashura.jpg?alt=media&token=69a83aee-37cc-4df2-a0f2-b545ec4812f6',
        title: 'ஆஷூராவுடைய நோன்பை நோற்க வேண்டிய நாட்கள் எவை?',
        download: 'https://firebasestorage.googleapis.com/v0/b/react-salafi-content.appspot.com/o/static%2Fpublications%2Fashura.pdf?alt=media&token=b9922fa6-d1b5-4043-9791-cba1bba7803c'
      }
    ];
  }

  if (key) {
    var publication =  _.find($scope.publications, { key: key });
    if (publication) {
      $scope.stateTitle = publication.title;
    }
  }

  if (!$scope.stateTitle) {
    $translate('PUBLICATIONS.TITLE').then(function (translation) {
      $scope.stateTitle = translation;
      $rootScope.title.text = $scope.stateTitle;
    });
  }

  $rootScope.title.text = $scope.stateTitle;
  $rootScope.title.hideHeader = false;
}]);