'use strict';

angular.module('ahlulhadith').service('SearchService', ['$q', '$timeout', 'FirebaseService', function($q, $timeout, firebaseService) {
  var service = this;

  this.search = function (app, query) {
    var defer = $q.defer();
    var requestRef = firebaseService.getSearchRequestsRef(app).push();
    var requestKey = requestRef.key;

    // TODO: Remove result listener
    var responseRef = firebaseService.getSearchResultRef(app, requestKey);
    var searchResult = firebaseService.firebaseObject(responseRef);
    
    searchResult.$watch(function () {

      if (!searchResult.$resolved) {
        return;
      }

      if (!searchResult.success || searchResult.$id !== requestKey) {
        return defer.reject();
      }

      if (searchResult.totalResults === 0) {
        return defer.resolve([]);
      }

      if (searchResult.totalResults > 0) {
        var promises = [];
        var postsMap = {};
        var itemsMap = {};
        angular.forEach(searchResult.results, function (result) {
          promises.push(firebaseService.getPost(app, result.postid).$loaded().then(function (post) {
            postsMap[post.$id] = post;
            return post;
          }));

          if (result.type === 'item') {
            promises.push(firebaseService.getItem(app, result.postid, result.itemid).$loaded().then(function (item) {
              itemsMap[result.postid + '-' + item.$id] = item;
              return item;
            }));
          }
        });

        var groupedResults = [];
        $q.all(promises).then(function () {
          angular.forEach(searchResult.results, function (result) {
            var post = postsMap[result.postid];
            var group = _.find(groupedResults, { postid: result.postid });
            if (!group) {
              group = {
                postid: result.postid,
                post: post,
                items: []
              };
              groupedResults.push(group);
            }
            if (result.type === 'item') {
              var item = itemsMap[result.postid + '-' + result.itemid];
              group.items.push(item);
            }
          });
          return defer.resolve(groupedResults);
        })
        .catch(function (err) {
          return defer.reject(err);
        });
      }

    });

    requestRef.set({ q: query });

    return defer.promise;
  };
}]);