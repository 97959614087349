'use strict';

angular
  .module('ahlulhadith')
  .directive('spreakerPlayer', spreakerPlayer);

function spreakerPlayer() {
  return {
    restrict: 'EA',
    replace: false,
    template: require('../../templates/partials/spreaker-player.html'),
    scope: true,
    controller: ['$scope', function($scope) {
      $scope.closeSpreakerPlayer = function () {
        console.log('closeSpreakerPlayer');
        $('.spreaker-toolbar').remove();
        $('.spreaker-holder').remove();
      };

      $scope.spreakerExpanded = false;
      $scope.toggleSpreakerExpandCollapse = function () {
        if ($scope.spreakerExpanded) {
          $('.spreaker-holder iframe').css('height', '80px');
        } else {
          $('.spreaker-holder iframe').css('height', '300px');
        }
        $scope.spreakerExpanded = !$scope.spreakerExpanded;
      };
    }]
  };
}