'use strict';

import NProgress from 'nprogress';

angular.module('ahlulhadith').config(['$stateProvider', '$locationProvider', '$urlMatcherFactoryProvider', '$urlRouterProvider', 'constants', function($stateProvider, $locationProvider, $urlMatcherFactoryProvider, $urlRouterProvider, constants) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);

  $urlRouterProvider.otherwise('/ta');

  $stateProvider

  .state('root', {
    abstract: true,
    template: require('../templates/root-home.html'),
    controller: 'RootController',
    resolve: {
      app: ['$stateParams', function($stateParams) {
        console.log('root');
        return $stateParams.app;
      }],
      categories: ['app', 'CategoriesService', function(app, categoriesService) {
        return categoriesService.get(app);
      }]
    }
  })

  .state('root.home', {
    url: '/{app: (?:ta|en)}',
    params: {
      app: {
        value: 'ta',
        squash: false
      }
    },
    template: require('../templates/home.html'),
    controller: 'HomeController',
    resolve: {
      featuredPostIds: ['app', 'FirebaseService', function(app, firebaseService) {
        return firebaseService.getFeaturedPostIds(app).$loaded();
      }]
    }
  })

  .state('root.feed', {
    url: '/:app/feed',
    template: require('../templates/posts.html'),
    controller: 'PostsController',
    resolve: {
      posts: ['app', 'FirebaseService', function(app, firebaseService) {
        return firebaseService.getAllPosts(app).$loaded();
      }]
    }
  })

  .state('root.post', {
    url: '/:app/posts/:postId/:slug',
    params: {
      slug: {
        value: null,
        squash: true
      },
    },
    resolve: {
      post: ['$stateParams', 'app', 'FirebaseService', function($stateParams, app, firebaseService) {
        return firebaseService.getPost(app, $stateParams.postId).$loaded();
      }],
      items: ['$stateParams', 'app', 'FirebaseService', function($stateParams, app, firebaseService) {
        return firebaseService.getItems(app, $stateParams.postId).$loaded();
      }],
      item: ['items', function(items) {
        return items[0];
      }]
    },
    controllerProvider: ['post', function(post) {
      if (post.category == constants.postCategories.audios) {
        if (post.count == 1) {
          return 'AudioItemController';
        }
        return 'AudioPostController';
      } else if (post.category == constants.postCategories.texts) {
        return 'TextPostController';
      } else if (post.category == constants.postCategories.images) {
        return 'ImagePostController';
      } else if (post.category == constants.postCategories.videos) {
        if (post.count == 1) {
          return 'VideoItemController';
        }
        return 'VideoPostController';
      }
    }],
    templateProvider: ['post', function(post) {
      if (post.category == constants.postCategories.audios) {
        if (post.count == 1) {
          return require('../templates/audio-item.html');
        }
        return require('../templates/audio-post.html');
      } else if (post.category == constants.postCategories.texts) {
        return require('../templates/text-post.html');
      } else if (post.category == constants.postCategories.images) {
        return require('../templates/image-post.html');
      } else if (post.category == constants.postCategories.videos) {
        if (post.count == 1) {
          return require('../templates/video-item.html');
        }
        return require('../templates/video-post.html');
      }
    }]
  })

  .state('root.category', {
    url: '/:app/categories/:categoryId/:lectureType',
    params: {
      lectureType: {
        value: 'all',
        squash: true
      },
    },
    template: require('../templates/category.html'),
    controller: 'CategoryController',
    resolve: {
      categoryId: ['$stateParams', function($stateParams) {
        return $stateParams.categoryId;
      }],
      lectureType: ['$stateParams', function($stateParams) {
        return $stateParams.lectureType;
      }],
    }
  })

  .state('root.lectureType', {
    url: '/:app/lecture-types/:lectureType',
    template: require('../templates/posts.html'),
    controller: 'LectureTypeController',
    resolve: {
      lectureType: ['$stateParams', function($stateParams) {
        return $stateParams.lectureType;
      }],
    }
  })

  .state('root.item', {
    url: '/:app/posts/:postId/items/:itemId/:slug',
    params: {
      slug: {
        value: null,
        squash: true
      },
    },
    resolve: {
      post: ['$stateParams', 'app', 'FirebaseService', function($stateParams, app, firebaseService) {
        return firebaseService.getPost(app, $stateParams.postId).$loaded();
      }],
      items: ['$stateParams', 'app', 'FirebaseService', function($stateParams, app, firebaseService) {
        return firebaseService.getItems(app, $stateParams.postId).$loaded();
      }],
      item: ['$stateParams', 'app', 'FirebaseService', function($stateParams, app, firebaseService) {
        return firebaseService.getItem(app, $stateParams.postId, $stateParams.itemId).$loaded();
      }]
    },
    controllerProvider: ['post', function(post) {
      if (post.category == constants.postCategories.audios) {
        return 'AudioItemController';
      } else if (post.category == constants.postCategories.videos) {
        return 'VideoItemController';
      } else if (post.category == constants.postCategories.images) {
        return 'ImagePostController';
      }
    }],
    templateProvider: ['post', function(post) {
      if (post.category == constants.postCategories.audios) {
        return require('../templates/audio-item.html');
      } else if (post.category == constants.postCategories.videos) {
        return require('../templates/video-item.html');
      } else if (post.category == constants.postCategories.images) {
        return require('../templates/image-post.html');
      }
    }]
  })

  .state('root.search', {
    url: '/:app/search?q',
    controller: 'SearchController',
    template: require('../templates/search.html'),
    resolve: {
      q: ['$stateParams', function ($stateParams) {
        return $stateParams.q;
      }]
    }
  })

  .state('root.aboutus', {
    url: '/:app/about-us',
    controller: 'AboutUsController',
    templateProvider: ['app', function(app) {
      if (app === 'ta') {
        return require('../static/ta-about-us.html');
      }
      if (app === 'en') {
        return require('../static/en-about-us.html');
      }
      // TODO: Make this generic
      // return templateRequest('/js/app/../static/' + app + '-about-us.html');
    }]
  })

  .state('root.biography', {
    url: '/:app/biography',
    controller: 'BiographyController',
    templateProvider: ['$templateRequest', 'app', function(templateRequest, app) {
      if (app === 'ta') {
        return require('../static/ta-biography.html');
      }
      if (app === 'en') {
        return require('../static/en-biography.html');
      }
    }]
  })

  .state('root.contactus', {
    url: '/:app/contact',
    controller: 'ContactController',
    template: require('../static/contact.html')
  })

  .state('root.appsLinks', {
    url: '/:app/apps-links',
    controller: 'AppsLinksController',
    template: require('../static/apps-links.html')
  })

  .state('root.recommendations', {
    url: '/:app/recommendations',
    controller: 'RecommendationsController',
    templateProvider: ['app', function(app) {
      if (app === 'ta') {
        return require('../static/ta-recommendations.html');
      }
      if (app === 'en') {
        return require('../static/en-recommendations.html');
      }
      // TODO: Make this generic
      // return templateRequest('/js/app/../static/' + app + '-recommendations.html');
    }]
  })

  .state('root.publications', {
    url: '/:app/publications/:publication',
    params: {
      publication: {
        value: null,
        squash: true
      },
    },
    controller: 'PublicationsController',
    template: require('../templates/publications.html'),
    resolve: {
      key: ['$stateParams', function($stateParams) {
        return $stateParams.publication;
      }]
    }
  })

  .state('root.privacyPolicy', {
    url: '/:app/privacy-policy',
    template: require('../static/privacy-policy.html')
  });

}]).run(['$transitions', function ($transitions) {

  $transitions.onStart({ }, function(trans) {
    NProgress.start();
    trans.promise.finally(NProgress.done);
  });

}]);