'use strict';

angular.module('ahlulhadith').controller('AudioPostController', ['constants', '$rootScope', '$scope', 'FirebaseService', 'angularPlayer', 'AudioService', 'app', 'post', 'items', 'categories', function(constants, $rootScope, $scope, firebaseService, angularPlayer, audioService, app, post, items, categories) {
  $scope.post = post;
  $scope.items = items;
  $scope.categories = categories;

  $scope.isCurrentItem = function(item) {
    var currentTrack = angularPlayer.currentTrackData();
    if (currentTrack && currentTrack.id == item.$id) {
      return true;
    }
  };

  $scope.buttonClass = function(item) {
    if (angularPlayer.isPlayingStatus() && $scope.isCurrentItem(item)) {
      return { 'fa-pause-circle': true };
    } else {
      return { 'fa-play-circle': true };
    }
  };

  $scope.toggle = function(item) {
    if (angularPlayer.isPlayingStatus() && $scope.isCurrentItem(item)) {
      angularPlayer.pause();
    } else {
      audioService.play(angularPlayer, {
        id: item.$id,
        title: item.title,
        url: item.link,
        postId: post.$id
      });
    }
  };

  $rootScope.title.text = $scope.post.title;
  $rootScope.title.hideHeader = false;
}]);